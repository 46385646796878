import router from "@/router"
import { computed, ref, unref } from "@vue/composition-api"
import { defineStore } from "pinia"
import { clearTokenFromLocalStorage } from "../composables/api"
import { useApi } from "../composables/use-api"
import { useQuery } from "../composables/use-query"

export const useAuthStore = defineStore("auth", () => {
  const userDisplayName = ref("-")
  const role = computed(() => me.value?.role)
  const isUnverified = computed(() => role.value?.slug == "unverified")
  const isDepartment = computed(() => role.value?.slug == "department")
  const isAdmin = computed(() => role.value?.slug == "admin")
  const isCommon = computed(() => role.value?.slug == "common")
  const tempMe = ref<any>(null)

  const TOKEN_NAME = process.env.VUE_APP_TOKEN_NAME ?? "eAulaId"
  const token = localStorage.getItem(TOKEN_NAME)

  const getMe = () => {
    return useApi()
      .get("/www/me")
      .then((res) => res.data)
      .catch((err) => {
        const statusCode = err.response.status as number
        const isUserNotFound = statusCode === 404
        const isUnathorized = statusCode === 401
        const forceSignOut = isUserNotFound || isUnathorized

        if (forceSignOut) signOut()
      })
  }

  const clearUser = () => {
    userDisplayName.value = "-"
    // me.value = null
    clearTokenFromLocalStorage()
  }

  const signOut = async () => {
    setTimeout(() => {
      clearUser()
      const currentRoute = router.currentRoute
      const redirectToAuthPage = currentRoute.fullPath.includes("/app")

      if (redirectToAuthPage) router.push("/masuk")
    }, 400)
  }
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    keyedData: me,
    isLoading,
    error,
    fetch,
  } = useQuery(["me"], getMe, {
    dataKey: "user",
    fetchOnInit: !!token,
    onSuccess: () => {
      userDisplayName.value = getUserDisplayName(me.value)
      tempMe.value = me.value
    },
  })

  const getUserDisplayName = (user: any) => {
    const _user = unref(user)
    if (!_user) return "-"
    const username = _user.username
    const displayName = _user.profile?.displayName

    return displayName ?? username
  }

  const isLinkedWithBapintar = computed(() => !!me.value?.bapintarSsoId)

  return {
    me,
    role,
    isUnverified,
    isDepartment,
    isAdmin,
    isCommon,
    error,
    isLoading,
    getMe,
    userDisplayName,
    getUserDisplayName,
    fetch,
    signOut,
    isLinkedWithBapintar,
  }
})
