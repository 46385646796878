import { computed, ref } from "@vue/composition-api"
import { defineStore } from "pinia"
import { Breadcrumb, Navigation } from "../types/Ui"
import { useAuthStore } from "./auth-store"

const allNavigations = {
  main: {
    text: "Utama",
    icon: "mdi-home-outline",
    to: "/app",
    exact: true,
  },
  reservation: {
    text: "Reservasi",
    icon: "mdi-calendar-month-outline",
    to: "/app/reservasi",
  },
  room: {
    text: "Aula",
    icon: "mdi-office-building-marker-outline",
    to: "/app/aula",
  },
  user: {
    text: "Pengguna",
    icon: "mdi-account-multiple-outline",
    to: "/app/pengguna",
  },
}

export const useUiStore = defineStore("ui", () => {
  const navigations = computed<Navigation[]>(() => {
    const authStore = useAuthStore()
    const { isAdmin, isCommon, isDepartment } = authStore
    if (isAdmin)
      return [
        allNavigations.main,
        allNavigations.reservation,
        allNavigations.room,
        allNavigations.user,
      ]
    if (isDepartment)
      return [
        allNavigations.main,
        allNavigations.reservation,
        allNavigations.room,
      ]
    if (isCommon) return [allNavigations.main, allNavigations.reservation]

    return []
  })
  const activeNavigation = ref<boolean | null>(null)
  const showBottomNavigation = ref<boolean | null>(true)
  const setShowBottomNavigation = (isShow: boolean) =>
    (showBottomNavigation.value = isShow)

  const appBarTitle = ref("")
  const setAppBarTitle = (title: string) => (appBarTitle.value = title)

  const breadcrumbs = ref<Breadcrumb[]>([
    {
      text: "Dashboard",
      disabled: false,
      to: "breadcrumbs_dashboard",
    },
    {
      text: "Link 1",
      disabled: false,
      to: "breadcrumbs_link_1",
    },
    {
      text: "Link 2",
      disabled: true,
      to: "breadcrumbs_link_2",
      color: "black",
    },
  ])
  const setBreadcrumbs = (_breadcrumbs: Breadcrumb[]) =>
    (breadcrumbs.value = _breadcrumbs)

  return {
    navigations,
    activeNavigation,
    appBarTitle,
    setAppBarTitle,
    breadcrumbs,
    setBreadcrumbs,
    showBottomNavigation,
    setShowBottomNavigation,
  }
})
