import {
  reactive,
  UnwrapRef,
  toRefs,
  watch,
  Ref,
  WatchOptions,
  isReactive,
  isRef,
} from "@vue/composition-api"

type MaybeUnwrap<T> = UnwrapRef<T> | undefined

type MaybeRef<T> = Ref<T> | T

export type QueryKey<T> = MaybeRef<T>

export type QueryFunction<D, E> = (...args: unknown[]) => Promise<D | E>

export type QueryOptions = WatchOptions<boolean> & {
  dataKey?: string
  fetchOnInit?: boolean
  onSuccess?: () => void
  onError?: () => void
  beforeFetching?: () => void
  afterFetching?: () => void
}

export type QueryState<D, E, K = D> = {
  isLoading: boolean
  data: D | undefined
  keyedData: K | undefined
  error: E | undefined
  errorData: unknown
  fetchCount: number
}

export const useQuery = <D = any, E = any, K = D>(
  queryKey: QueryKey<unknown>[],
  queryFunction: QueryFunction<D, E>,
  options?: QueryOptions
) => {
  const {
    immediate = false,
    deep = false,
    dataKey,
    fetchOnInit = true,
    onError,
    onSuccess,
    beforeFetching,
    afterFetching,
  } = options ?? {}

  const state = reactive<QueryState<D, E, K>>({
    isLoading: false,
    data: undefined,
    keyedData: undefined,
    error: undefined,
    errorData: undefined,
    fetchCount: 0,
  })

  const fetch = (...args: unknown[]) => {
    state.isLoading = true
    beforeFetching?.()

    queryFunction(args)
      .then((data) => {
        state.data = data as MaybeUnwrap<D>
        if (dataKey) {
          const _data = data && ((data as any)[dataKey] as MaybeUnwrap<K>)
          state.keyedData = _data ?? undefined
        }

        onSuccess?.()
      })
      .catch((error) => {
        state.error = error as MaybeUnwrap<E>
        state.errorData = error?.response?.data
        onError?.()
      })
      .finally(() => {
        state.fetchCount++
        state.isLoading = false
        afterFetching?.()
      })
  }

  const queryKeyToWatch = queryKey.filter((key) => {
    const isRefOrReactive = isReactive(key) || isRef(key)
    if (isRefOrReactive) return key
  })

  queryKey.map((key) => {
    const isRefOrReactive = isReactive(key) || isRef(key)
    if (isRefOrReactive) queryKeyToWatch.push(key)
  })

  watch([...queryKeyToWatch], () => fetch(), {
    immediate,
    deep,
  })

  if (fetchOnInit) fetch()

  return {
    ...toRefs(state),
    fetch,
  }
}
