import { updateBreadcrumb } from "@/core/helpers/update-breadcrumbs"
import { useAuthStore } from "@/core/stores/auth-store"
import { useTitle } from "@vueuse/core"
import Vue from "vue"
import VueRouter, { Route, RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LandingIndex",
    component: () => import("@/modules/landing/pages/index.vue"),
    meta: {
      title: "Aplikasi Reservasi Aula Kota Banjarmasin",
    },
  },
  {
    path: "/masuk",
    name: "SignIn",
    component: () => import("@/modules/landing/pages/sign-in.vue"),
    meta: {
      title: "Masuk",
    },
  },
  {
    path: "/daftar",
    name: "SignUp",
    component: () => import("@/modules/landing/pages/sign-up.vue"),
    meta: {
      title: "Daftar",
    },
  },
  {
    path: "/jadwal/:ownerId",
    props: true,
    name: "ScheduleByDepartment",
    component: () =>
      import("@/modules/landing/pages/schedule-by-department.vue"),
  },
  {
    path: "/app",
    name: "AppLayout",
    component: () => import("@/core/ui/layouts/k-layout-constrained.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Main",
        component: () => import("@/modules/app/dashboards/page/index.vue"),
        meta: {
          title: "Utama",
        },
      },
      {
        path: "profil",
        name: "UpdateProfile",
        component: () => import("@/modules/app/www/pages/update-profile.vue"),
        meta: {
          title: "Profil",
        },
      },
      {
        path: "ganti-password",
        name: "ChangePassword",
        component: () => import("@/modules/app/www/pages/change-password.vue"),
        meta: {
          title: "Ganti Password",
        },
      },
      {
        path: "reservasi",
        name: "ReservationIndex",
        component: () => import("@/modules/app/reservations/pages/index.vue"),
        meta: {
          title: "Reservasi",
        },
      },
      {
        path: "reservasi/tambah",
        name: "ReservationForm",
        component: () => import("@/modules/app/reservations/pages/form.vue"),
        meta: {
          title: "Buat Reservasi",
        },
      },
      {
        path: "reservasi/:id",
        props: true,
        name: "ReservationForm",
        component: () => import("@/modules/app/reservations/pages/form.vue"),
        meta: {
          title: "Edit Reservasi",
        },
      },
      {
        path: "aula",
        name: "RoomIndex",
        component: () => import("@/modules/app/rooms/pages/index.vue"),
        meta: {
          title: "Aula",
        },
      },
      {
        path: "aula/:roomId",
        props: true,
        name: "RoomDetail",
        component: () => import("@/modules/app/rooms/pages/detail.vue"),
        meta: {
          title: "Detail Aula",
        },
      },
      {
        path: "pengguna",
        name: "UserIndex",
        component: () => import("@/modules/app/users/pages/index.vue"),
        meta: {
          title: "Pengguna",
        },
      },
    ],
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
})

const updateTitle = (to: Route) => {
  const defaultTitle = "Aplikasi Reservasi Aula"
  const titleMeta = to?.meta?.title

  useTitle(titleMeta ?? defaultTitle, {
    titleTemplate: "%s | e-Aula",
  })
}

router.beforeEach(async (to, from, next) => {
  updateTitle(to)

  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (!isRequiresAuth) return next()

  const { me, getMe } = useAuthStore()

  let error: any = null
  if (!me) await getMe().catch((err: any) => (error = err.response?.data))
  const isUnauthorized = error?.message === "Unauthorized"
  const onAuthPage = to.name === "SignIn" || to.name === "SignUp"
  const onAppPage = to.fullPath.includes("/app")
  const redirectToLoginPage = isUnauthorized && isRequiresAuth && onAppPage
  const redirectToAppPage = onAuthPage && me.value && !isUnauthorized

  if (redirectToLoginPage)
    return next({
      path: "/masuk",
      query: { redirect: to.fullPath },
    })

  if (redirectToAppPage) return next({ path: "/app" })

  updateBreadcrumb(to)

  next()
})

export default router
