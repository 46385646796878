import { Route } from "vue-router"
import { useApi } from "../composables/use-api"
import { useUiStore } from "../stores/ui-store"

export const updateBreadcrumb = async (to: Route) => {
  const arrayPath: any[] = to.fullPath.split("/")
  const breadcrumbs: any[] = []

  for (const [index, path] of arrayPath.entries()) {
    if (path) {
      const to = `${arrayPath.slice(0, index + 1).join("/")}`
      let text = path === "app" ? "UTAMA" : String(path).toUpperCase()
      const disabled = arrayPath.length - 1 === index
      const isUuid = path.indexOf("-") === 8

      if (isUuid) {
        const modules = {
          aula: "rooms",
          reservasi: "reservations",
        }
        const pathModul: keyof typeof modules = arrayPath[index - 1]
        const module = modules[pathModul]
        // const resultModule = module.slice(0, -1)
        const uuid = path as string
        const result = await useApi().get(`/${module}/${uuid}`)

        if (pathModul === "aula") {
          text = result.data.room.name
        }
      }

      breadcrumbs.push({
        to,
        text,
        disabled,
        color: disabled ? "black" : "primary",
      })
    }
  }

  // arrayPath.map((path, index) => {
  //   if (path) {
  //     const to = `${arrayPath.slice(0, index + 1).join("/")}`
  //     const text = path === "app" ? "UTAMA" : String(path).toUpperCase()
  //     const disabled = arrayPath.length - 1 === index
  //     const isUuid = path.indexOf("-") === 8
  //     const pathModul = arrayPath[index - 1]
  //     const modules = {
  //       aula: "rooms",
  //     }

  //     breadcrumbs.push({
  //       to,
  //       text,
  //       disabled,
  //       color: disabled ? "black" : "primary",
  //     })
  //   }
  // })

  const uiStore = useUiStore()
  uiStore.setBreadcrumbs(breadcrumbs)
}
