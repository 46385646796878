import axios, { AxiosRequestConfig } from "axios"
import { getApiBaseUrl } from "../helpers/get-api-base-url"

export type ApiConfig = AxiosRequestConfig<any> | undefined

const TOKEN_NAME = process.env.VUE_APP_TOKEN_NAME ?? "eAulaId"

export const useApi = (config: ApiConfig = {}) => {
  const token = localStorage.getItem(TOKEN_NAME)

  const instance = axios.create({
    baseURL: getApiBaseUrl(),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
    ...config,
  })

  if (token)
    instance.defaults.headers.common["authorization"] = "Bearer " + token

  return instance
}

export const setTokenToLocalStorage = (token: string) => {
  if (token) localStorage.setItem(TOKEN_NAME, token)
}
